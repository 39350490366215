<template>
  <div>
    <v-container fluid>
      <v-row align="center" justify="space-between" class="font-weight-black">
        <v-col cols="12" sm="11">
          <v-breadcrumbs :items="breadcrumbs" divider="/" v-if="!omiteBreadcrumbs">
            <template v-slot:item="props">
              <v-breadcrumbs-item
                :disabled="props.item.disabled"
                :class="{
                  'ApuracaoContrato_breadcrumbs-item': true,
                  'ApuracaoContrato_breadcrumbs-item_disabled': props.item.disabled,
                }">
                {{ props.item.text }}
              </v-breadcrumbs-item>
            </template>
          </v-breadcrumbs>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="12" sm="1" class="text-right" v-if="exibirFiltro">
          <v-badge
              bordered
              color="orange"
              icon="mdi-lock"
              overlap
              v-show="!abrirFiltro"
              v-if="habilitarIconBadge">
              <v-icon v-show="!abrirFiltro" @click="abrirFiltro = !abrirFiltro">filter_list</v-icon>
          </v-badge>
          <v-icon v-else v-show="!abrirFiltro" @click="abrirFiltro = !abrirFiltro">filter_list</v-icon>
        </v-col>
      </v-row>
    </v-container>

    <apuracao-contrato-filtros
      ref="filtrosComplementares"
      v-model="abrirFiltro"
      :desabilita-aplicar="desabilitaAplicar"
      :filtros-complementares="filtrosComplementares"
      @ApuracaoContratoFiltros__AplicaFiltros="aplicarFiltros"
      @ApuracaoContratoFiltros_conteinerCarregado="abrirTooltip"
      @FiltroRapido__HabilitaIconBadge="habilitaIconBadge"
      @ApuracaoContratoFiltros__setaIdContratoTipo="setaIdContratoTipo">
    </apuracao-contrato-filtros>

    <apuracao-contrato-filtros-obrigatorios
      :id-contrato-tipo="idContratoTipo"
      @ApuracaoContratoFiltrosObrigatorios__AplicaFiltros="aplicarFiltrosObrigatorios"
      @ApuracaoContratoFiltrosObrigatorios__FiltrosObrigatoriosPreenchidos="filtrosObrigatoriosPreenchidos"
    />
    <div class="card-title ml-12 mt-1" style="font-size: 14px; font-weight:normal;">
      {{ $t('message.filtrar_apuracao_contrato') }}
    </div>

    <v-card style="width: 95%;" class="ml-8 mt-10">
    <apuracao-contrato-tabela
      :apuracoes="apuracoes"
      :total-page="totalPage"
      :selecionar-item="selecionarItem"
      :filtros="filtros"
      @ApuracaoContratoTabela__AplicaFiltros="aplicarFiltrosObrigatorios"
    />
  </v-card>
    <v-container pt-6 fluid v-resize="onResize">
    <v-row v-if="exibirBotoesFinalizar">
      <v-footer
          v-bind="{fixed: true}"
          :style="`width: ${tamanhoRodape}px; background-color: #EEEEEE;`"
      >
        <v-spacer v-if="!xs"></v-spacer>
        <v-btn class="mr-2 my-auto"
          v-if="indFinalizarSelecionados"
          @click="finalizarSelecionados">
          {{ $t('label.finalizar_selecionados') }}
        </v-btn>
        <v-btn class="mr-15 my-auto mr-3"
          color="primary"
          v-if="apuracoes.length"
          @click="finalizarTudo">
          {{ $t('label.finalizar_tudo') }}
        </v-btn>
      </v-footer>
    </v-row>
    </v-container>

    <dialog-finalizar-apuracoes
      ref="modalFinalizarApuracoes"
      :apuracoes-inconsistentes="apuracoesInconsistentes"
      @agree="finalizarApuracoesIntegral">
  </dialog-finalizar-apuracoes>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import moment from 'moment';
import { generateComputed } from '../../common/functions/roles-computed-generator';
import ApuracaoContratoFiltros from './ApuracaoContratoFiltros';
import ApuracaoContratoFiltrosObrigatorios from './ApuracaoContratoFiltrosObrigatorios';
import ApuracaoContratoTabela from './ApuracaoContratoTabela';
import ApuracaoContratoControleBreadcrumbs from './ApuracaoContratoControleBreadcrumbs';
import DialogFinalizarApuracoes from './modals/DialogFinalizarApuracoes';
import {
  buscarApuracaoFornecedorPorContrato,
  finalizarApuracaoLoteFornecedor,
} from '../../common/resources/apuracao/apuracao-contrato';
import {
  formatlastDayOfTheMonth,
} from '../../common/functions/date-utils';

export default {
  name: 'ApuracaoContrato',
  components: {
    ApuracaoContratoFiltros,
    ApuracaoContratoFiltrosObrigatorios,
    ApuracaoContratoTabela,
    DialogFinalizarApuracoes,
  },
  mixins: [ApuracaoContratoControleBreadcrumbs],
  data() {
    return {
      workflowAcaoMagaluResource: this.$api.workflowAcaoMagalu(this.$resource),
      abrirFiltro: false,
      omiteBreadcrumbs: false,
      aplicarFiltroNoCarregamento: true,
      filtros: {},
      filtrosComplementares: {},
      habilitarIconBadge: false,
      showTooltip: false,
      tamanhoFiltros: 0,
      desabilitaAplicar: true,
      indFinalizarTudo: false,
      apuracoes: [],
      totalPage: 0,
      apuracoesSelecionadas: [],
      apuracoesInconsistentes: [],
      pagination: {
        itemsPerPage: 10,
        page: 1,
      },
      tamanhoTela: window.innerWidth,
      ordenacao: '',
      asc: '',
      idContratoTipo: null,
      isEditandoValorBase: false,
    };
  },
  computed: {
    ...generateComputed('APU_CONTR', [
      'canAccessPage',
    ]),
    xs() {
      return this.$vuetify.breakpoint.xs;
    },
    lg() {
      return this.$vuetify.breakpoint.lgAndUp;
    },
    tamanhoRodape() {
      return this.tamanhoTela - (this.abrirFiltro && this.lg ? this.tamanhoFiltros : 0);
    },
    existeApuracoesSelecionadas() {
      return this.apuracoesSelecionadas.filter((a) => a.selecionado).length > 0;
    },
    indFinalizarSelecionados() {
      return !this.indFinalizarTudo
        && (this.apuracoesSelecionadas.length);
    },
    exibirBotoesFinalizar() {
      return !this.isEditandoValorBase;
    },
  },
  watch: {
    exibirFiltro(val) {
      if (val) {
        setTimeout(() => this.$refs.totalizadores.aplicaFiltros(this.filtros),
          3E2);
      }
    },
  },
  methods: {
    ...mapActions('filtros', [
      'setFiltroApuracaoContrato',
      'setFiltroApuracaoContratoNivel1',
    ]),
    accessForbidden() {
      if (!this.canAccessPage) {
        this.$router.push({ name: 'inicio' });
        this.$toast(this.$t('message.acesso_negado'));
      }
    },
    onResize() {
      this.tamanhoTela = window.innerWidth;
    },
    selecionarItem(itemSelecionado, e) {
      if (e) e.stopPropagation();
      const apuracaoSelecionada = this.apuracoesSelecionadas.find((a) => a.idApuracao === itemSelecionado.idApuracao);
      if (apuracaoSelecionada && !apuracaoSelecionada.selecionado) {
        const index = this.apuracoesSelecionadas.findIndex((a) => a.idApuracao === itemSelecionado.idApuracao);
        this.apuracoesSelecionadas.splice(index, 1);
      }
      if (itemSelecionado.selecionado) {
        this.apuracoesSelecionadas.push(itemSelecionado);
      }
    },
    aplicarFiltros(filtrosLaterais, mudouFiltrosLaterais, isBuscaFiltroLateral = false) {
      this.filtrosComplementares = filtrosLaterais;
      if (isBuscaFiltroLateral) {
        this.pagination.page = 1;
      }
      const params = this.formatarFiltros(this.filtros, this.filtrosComplementares);
      params.asc = this.asc;
      params.colunaOrdenacao = this.ordenacao;
      this.buscarApuracoes(params);
      this.abrirFiltro = false;
    },
    aplicarFiltrosObrigatorios(paginationTab) {
      if (paginationTab) {
        this.pagination = paginationTab;
        if (this.pagination.sortBy && this.pagination.sortBy.length) {
          const { sortBy, sortDesc } = this.pagination;
          this.asc = !sortDesc[0];
          this.ordenacao = this.formatarOrdenacaoPorValor(sortBy);
        }
        this.$refs.filtrosComplementares.$refs.filtrosRapidos.aplicar();
      } else {
        this.apuracoes = [];
        this.pagination.page = 1;
        this.$refs.filtrosComplementares.setarIsPaginacaoAlterada(false);
        this.$refs.filtrosComplementares.$refs.filtrosRapidos.aplicar();
      }
    },
    filtrosObrigatoriosPreenchidos(filtrosObrigatorios) {
      if (filtrosObrigatorios) {
        this.filtros = filtrosObrigatorios;
        this.desabilitaAplicar = false;
      } else if (!filtrosObrigatorios && filtrosObrigatorios !== undefined) {
        this.desabilitaAplicar = true;
      }
      this.apuracoes = [];
    },
    formatarOrdenacaoPorValor(sortBy) {
      if (sortBy && sortBy.length) {
        const valor = sortBy[0];
        if (valor === 'periodoVigencia') {
          return 'dc.data_inicio';
        }
        if (valor === 'qtdProduto') {
          return 1;
        }
        let valorFormatado = valor.replace(/([A-Z])/g, '_$1').toLowerCase();
        valorFormatado = `dc.${valorFormatado}`;
        return valorFormatado;
      }
      return null;
    },
    abrirTooltip() {
      setTimeout(() => {
        this.showTooltip = true;
      }, 1000);
      this.tamanhoFiltros = document.getElementById('ApuracaoFiltros__filtro_rapido').offsetWidth;
    },
    buscarApuracoes(params) {
      this.apuracoesSelecionadas = [];
      return buscarApuracaoFornecedorPorContrato(params, this.$resource)
        .then((response) => {
          this.asc = '';
          this.ordenacao = '';
          this.apuracoes = response.data.resposta || [];
          this.totalPage = response.data.quantidadeRegistrosPagina;
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    habilitaIconBadge(valor) {
      this.habilitarIconBadge = valor;
    },
    formatarFiltros(filtros, filtrosComplementares) {
      const filtrosFormatados = {
        ...filtrosComplementares,
        id_acao_tipo: filtros.tipoAcao,
        data_inicio_apuracao: moment(filtros.dataInicio, 'YYYY-MM').format('YYYY-MM-DD'),
        data_fim_apuracao: formatlastDayOfTheMonth(moment(filtros.dataFim, 'YYYY-MM').format('MM-YYYY'), 'YYYY-MM-DD'),
        ids_categoria: this.extrairIds(filtros.categoria),
        status: filtrosComplementares.apresentar_apuracoes_finalizadas ? ['AGUARDANDO_APURACAO', 'APROVADO'] : ['AGUARDANDO_APURACAO'],
        tamanhoPagina: this.pagination.itemsPerPage,
        numeroPagina: this.pagination.page,
        is_apuracao_epoca: true,
        id_contrato_tipo: this.idContratoTipo,
      };
      return filtrosFormatados;
    },
    extrairIds(array) {
      if (!array.length) {
        return null;
      }
      return array.map((el) => el.id);
    },
    async finalizarTudo() {
      this.apuracoesSelecionadas = this.apuracoes;
      await this.finalizarSelecionados();
    },
    finalizarSelecionados() {
      this.apuracoesInconsistentes = [];

      this.apuracoesSelecionadas.forEach((a) => {
        if (this.apuracaoPossuiInconsistencia(a)) {
          this.apuracoesInconsistentes.push(a);
        }
      });
      this.apuracoesSelecionadas = this.apuracoesSelecionadas.filter((a) => !this.apuracaoPossuiInconsistencia(a) && a.statusApuracao !== 'APROVADO');
      setTimeout(() => this.$refs.modalFinalizarApuracoes.open(this.apuracoesSelecionadas));
    },
    finalizarApuracoesIntegral() {
      const filtros = this.prepararParametros();
      finalizarApuracaoLoteFornecedor(filtros, this.$resource)
        .then(() => {
          this.aplicarFiltrosObrigatorios();
          this.$refs.modalFinalizarApuracoes.close();
          this.$toast(this.$tc('message.apuracao_finalizada_sucesso', 2));
        })
        .catch((err) => {
          this.$error(this, err);
          this.$refs.modalFinalizarApuracoes.close();
        });
    },
    prepararParametros() {
      const filtros = { ...this.filtros, ...this.filtrosComplementares };
      if (this.apuracoesSelecionadas.length) {
        const idsApuracao = [];

        this.apuracoesSelecionadas.forEach((apuracao) => {
          if (apuracao.selecionado) {
            idsApuracao.push(Number(apuracao.idApuracao));
          }
        });
        filtros.apuracoesSelecionadas = idsApuracao;
        filtros.apuracoesFornecedores = [];
      }
      filtros.idTipoAcao = [filtros.tipoAcao];
      filtros.idGrupoFornecedor = filtros.id_grupo_fornecedor;
      filtros.status = ['AGUARDANDO_APURACAO'];

      delete filtros.tipoAcao;
      delete filtros.id_grupo_fornecedor;
      delete filtros.dataInicio;
      delete filtros.dataFim;
      delete filtros.grupo_fornecedor;
      delete filtros.categoria;
      delete filtros.numeroPagina;
      delete filtros.tamanhoPagina;

      return filtros;
    },
    apuracaoPossuiInconsistencia(apuracao) {
      return (apuracao.indJustificativa && !apuracao.justificativaAlterarRecebimento)
      || (typeof apuracao.vlrRealizado === 'undefined' || apuracao.vlrRealizado === '')
      || !apuracao.possuiNota || !apuracao.existeAtingimento;
    },
    setaIdContratoTipo(idContratoTipo) {
      this.idContratoTipo = idContratoTipo;
    },
  },
  mounted() {
    if (!this.canAccessPage) {
      window.setTimeout(() => this.accessForbidden(), 1E3);
    }
  },
};
</script>
<style lang="scss">
.ApuracaoContrato_breadcrumbs-item_disabled {
  color: grey;
  pointer-events: none;
}
.ApuracaoContrato_breadcrumbs-item {
  color: grey;
  cursor: pointer;
}
.ApuracaoContrato_breadcrumbs-item:hover {
  color: #3f3f3f;
}
</style>
