<template>
  <div>
    <v-container pt-6 fluid v-resize="onResize">
      <v-row>
        <v-data-table
          :key="panelKey"
          item-key="idApuracao"
          :headers="headersApuracao"
          :items="apuracoes"
          :options.sync="pagination"
          :server-items-length="totalPage"
          class="elevation-0"
          :no-data-text="$t('label.tabela_sem_conteudo')"
          :footer-props="{
            itemsPerPageOptions: [10, 25, 50],
          }">
          <template v-slot:[`item.checkbox`]="{ item }">
            <v-checkbox
              v-model="item.selecionado"
              v-if="!statusAprovado(item)"
              hide-details
              class="shrink pt-0 mt-0"
              @click.stop="(e) => selecionarItem(item, e)">
            </v-checkbox>
          </template>
          <template v-slot:[`item.icon`]="{ item }">
            <v-tooltip bottom v-if="!statusAprovado(item)">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                    color="orange darken-1"
                    dark
                    v-bind="attrs"
                    v-on="on"
                >
                  report_problem
                </v-icon>
              </template>
              <span>{{ $t('message.periodo_total_ou_parte_apurado') }}</span>
            </v-tooltip>
            <v-tooltip bottom v-if="statusAprovado(item)">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                    color="deep-orange accent-4"
                    dark
                    v-bind="attrs"
                    v-on="on"
                >
                  info
                </v-icon>
              </template>
              <span>{{ $t('label.periodo_apurado') }}</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.acoes`]="{ item }">
            <v-menu bottom
              origin="center center"
              transition="scale-transition">
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  :id="'more_vert_' + item.id"
                  icon>
                  <v-icon>more_vert</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  @click="detalharApuracao(item)">
                  <v-list-item-action>
                    <v-icon>details</v-icon>
                  </v-list-item-action>
                  <v-list-item-title>{{ $tc('label.detalhe', 2) }}</v-list-item-title>
                </v-list-item>
                <v-list-item
                  v-if="!statusAprovado(item)"
                  @click="abrirModalCancelamento(item)">
                  <v-list-item-action>
                    <v-icon>cancel</v-icon>
                  </v-list-item-action>
                  <v-list-item-title>{{ $tc('label.cancelar', 1) }}</v-list-item-title>
                </v-list-item>
                <v-list-item
                  @click="exportarNotas(item, false)">
                  <v-list-item-action>
                    <v-icon>get_app</v-icon>
                  </v-list-item-action>
                  <v-list-item-title>{{ $tc('label.exportar_nota', 2) }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
          <template v-slot:[`item.categoria`]="{ item }">
            <v-container fluid v-if="item.existeFamiliaSelecionada">
              <v-row>
                <span class="mt-2">
                  {{ item.categoria }}
                </span>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      icon
                      v-on="on"
                      @click="abrirModalCategoriaNivelFamilia(item.idAcao)"
                    >
                      <v-icon
                        class="ml-2"
                        color="orange darken-1"
                        dark>
                        report_problem
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $tc('label.detalhamento_familias_selecionadas', 1) }}</span>
                </v-tooltip>
              </v-row>
            </v-container>
            <span class="mt-2" v-else>
              {{ item.categoria }}
            </span>
          </template>
          <template v-slot:[`item.periodoVigencia`]="{ item }">
            {{ formatarVigencia(item)}}
          </template>
          <template v-slot:[`item.calculoApuracao`]="{ item }">
            {{ formatarCalculoApuracao(item.calculoApuracao)}}
          </template>
          <template v-slot:[`item.verba`]="{ item }">
            <tr v-if="item.indMetaIntervalo">
              <td>
                <span class="d-block">
                  {{ item.faixaAtingida ? item.formaBonificacao === 'FIXO' ?
                    getMoney(item.faixaAtingida) : getPercent(item.faixaAtingida) : $tc('label.sem_atingimento', 1)}}
                </span>
              </td>
              <td>
                <v-btn
                  icon
                  @click="abrirModalIntervaloMeta(item)"
                  class="mx-2 text-left"
                >
                  <v-icon>
                    add_box
                  </v-icon>
                </v-btn>
              </td>
            </tr>
            <span v-else>
              {{ getPercent(item.verba) }}
            </span>
          </template>
          <template v-slot:[`item.vlrRealizado`]="{ item }">
            <span v-if="item.indMetaIntervalo">
              {{ item.vlrRealizado ? getMoney(item.vlrRealizado) : '-'}}
              <v-btn
                v-if="indCalculoSellout(item) && item.vlrRealizado"
                icon
                @click="verificarQuantidadeRegristrosExportacao(item)"
                class="mx-2"
              >
                <v-icon>
                  get_app
                </v-icon>
              </v-btn>
            </span>
            <span v-else class="grey--text caption">
              <br/>
              {{'-'}}
            </span>
          </template>
          <template v-slot:[`item.valorBase`]="{ item }">
            {{ getMoney(item.valorBase)}}
          </template>
          <template v-slot:[`item.recebimentoDefinido`]="{ item }">
            <dialog-edit-recebimento-definido
              :id="`recDefinido-${item.idApuracao}`"
              :ref="`recDefinido-${item.idApuracao}`"
              :item="item"
              :ind-recebimento-definido-alterado="indRecebimentoDefinidoAlterado"
              :salvar-valor-alterado="salvarValorAlterado"
            >
            </dialog-edit-recebimento-definido>
          </template>
          <template v-slot:[`item.ajusteGeral`]="{ item }">
            <v-btn
                icon
                @click="abrirModalNotaDebitoInformacoes(item)"
                class="mx-2"
              >
                <v-icon>
                  edit_note
                </v-icon>
              </v-btn>
          </template>
        </v-data-table>
      </v-row>
    </v-container>

    <v-dialog
      v-model="dialogPlanejamentoContrato"
      v-if="dialogPlanejamentoContrato"
      fullscreen
      hide-overlay
      persistent
      transition="dialog-bottom-transition">
      <v-card class="ModalPlanejamentoContrato">
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="closeDialogPlanejamentoContrato()">
            <v-icon>close</v-icon>
          </v-btn>
          <v-toolbar-title><b>{{ $tc('title.planejamento_contrato', 1).toUpperCase()}}</b></v-toolbar-title>
        </v-toolbar>

        <v-card-text>
          <planejamento-contrato-form
            ref="modalForm"
            :somente-leitura="true"
            :id-contrato-fechamento="contrato.idContrato">
          </planejamento-contrato-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <dialog-apuracao-contrato-cancelamento
      ref="dialogCancelamento"
      :confirmarCancelarApuracao="confirmarCancelarApuracao">
    </dialog-apuracao-contrato-cancelamento>

    <dialog-categoria-nivel-familia
      ref="dialogCategoriaNivelFamilia">
    </dialog-categoria-nivel-familia>

    <dialog-apuracao-contrato-meta-intervalo
      ref="dialogMetaIntervalo">
    </dialog-apuracao-contrato-meta-intervalo>
    <dialog-apuracao-contrato-nota-debito-informacoes
      ref="dialogNotaDebitoInformacoes">
    </dialog-apuracao-contrato-nota-debito-informacoes>
  </div>
</template>

<script>

import ApuracaoContratoTabData from './ApuracaoContratoTabelaData';
import TooltipAjuda from '../../shared-components/TooltipAjuda';

export default {
  name: 'ApuracaoContratoTabMensal',
  mixins: [
    ApuracaoContratoTabData,
  ],
  components: {
    TooltipAjuda,
  },
  props: {
    apuracoes: {
      type: Array,
      default: () => ([]),
    },
    totalPage: {
      type: Number,
      default: 0,
    },
    filtros: {
      type: Object,
    },
    selecionarItem: Function,
  },
  data() {
    return {
      pagination: {
        itemsPerPage: 10,
        page: 1,
      },
      lastPagination: {},
    };
  },
  computed: {
  },
  watch: {
    pagination: {
      handler(pagination) {
        if (this.lastPagination.page === null || typeof this.lastPagination.page === 'undefined') {
          this.lastPagination = pagination;
          return;
        }
        if (this.mesmaPagina(pagination, this.lastPagination)) {
          return;
        }
        this.resetarExpanded();
        this.lastPagination = pagination;
        this.$emit('ApuracaoContratoTabela__AplicaFiltros', this.pagination);
      },
      deep: true,
    },
  },
  methods: {
  },
};
</script>
<style lang="scss">
  .ModalPlanejamentoContrato {
    background-color: #EEEEEE !important;
  }
</style>
