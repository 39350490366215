<template>
  <v-dialog v-model="dialog" max-width="720px">
    <v-card style="min-height:700px; height: 100%;">
      <v-form ref="formOrigem"
      lazy-validation
      auto-complete="off">
        <v-card-title class="text-h5 font-weight-bold mb-1">
            {{ $tc('label.ajuste_geral', 2).toUpperCase() }}
        </v-card-title>
        <v-card-subtitle style="font-size: 14px;">
          {{ $tc('title.ajustes_gerais') }}
        </v-card-subtitle>
        <v-card-text>
          <v-card style="min-height:200px; height: 100%; border: 1px solid; padding:6px;" class="mb-4">
            <v-card-title class="text-h6 font-weight-bold">
              {{ $tc('label.fornecedor_faturado', 2) }}
            </v-card-title>
            <v-card-subtitle style="font-size: 12px;"  class="mb-0">
              {{ $tc('title.alteracao_informacoes_nd') }}
            </v-card-subtitle>
            <v-col cols="12" class="mt-0" sm="12">
              <v-autocomplete
                id="dialog-nota-debito-informacoes-fornecedorpagador"
                class="custom-autocomplete mb-5"
                v-model="apuracaoInformacoesND.fornecedorPagador"
                return-object
                :items="fornecedoresPagadores"
                :label= "$tc('label.cnpj', 1)"
                :no-data-text="$tc('message.nenhum_registro', 1)"
                item-text="codNomeCnpj"
                item-value="id"
                :hint="apuracaoInformacoesND.codNomeCnpj ? `${$tc('label.cnpj_atual', 1)}: ${apuracaoInformacoesND.codNomeCnpj}` : ''"
                persistent-hint
                :disabled="somenteLeitura"
                :placeholder="$tc('message.digite_para_pesquisar', 1)"
                :clearable="true"
                @change="verificarFornecedorPagadorPreenchido()">
              </v-autocomplete>
              <v-textarea
                id="dialog-nota-debito-informacoes-justificativa-fornecedorpagador"
                ref="textareaJustificativaPagador"
                name="FornecedorPagador_justificativa"
                v-model="apuracaoInformacoesND.justificativaFornecedorPagador"
                rows="1"
                :label="`${$tc('label.justificativa', 1)}`"
                :required="!desabilitarJustificativaFornecedorPagador"
                :rules="rules.requiredFunctionOpcional(!desabilitarJustificativaFornecedorPagador, labelFornecedorPagador)"
                :disabled="somenteLeitura || desabilitarJustificativaFornecedorPagador"
                :counter="255"
                maxlength="255">
              </v-textarea>
            </v-col>
          </v-card>
          <v-card style="min-height:200px; height: 100%; border: 1px solid">
            <v-card-title class="text-h6 font-weight-bold">
              {{ $tc('label.prazo_pagamento', 2) }}
            </v-card-title>
            <v-col cols="12" sm="12">
              <v-text-field
                id="dialog-nota-debito-informacoes-prazo-pagamento"
                v-model="apuracaoInformacoesND.prazoPagamento"
                :label="$tc('label.novo_prazo_pagamento', 1)"
                type="Number"
                min="1"
                :hint="$tc('label.prazo_pagamento_padrao_nd', 1)"
                :persistent-hint="true"
                :disabled="somenteLeitura"
                style="padding-top:2px;"
                @change="prazoPagamentoAlterado()"
              />
              <v-textarea
                id="dialog-nota-debito-informacoes-justificativa-prazo-pagamento"
                index="1"
                ref="textareaJustificativaPagamento"
                name="PrazoPagamento_justificativa"
                v-model="apuracaoInformacoesND.justificativaPrazoPagamento"
                rows="1"
                :label="$tc('label.justificativa', 1)"
                :required="prazoPagamentoPreenchido"
                :rules="rules.requiredFunctionOpcional(prazoPagamentoPreenchido, labelPrazoPagamento)"
                :disabled="somenteLeitura || !prazoPagamentoPreenchido"
                :counter="255"
                maxlength="255">
              </v-textarea>
            </v-col>
          </v-card>
          <v-textarea
            id="dialog-nota-debito-informacoes-observacao"
            index="1"
            ref="textareaObservacao"
            name="Observacao_justificativa"
            v-model="apuracaoInformacoesND.observacaoNotaDebito"
            rows="1"
            class="mt-4"
            :label="`${$tc('label.observacao_nota_debito', 1)}`"
            :disabled="somenteLeitura"
            :counter="255"
            maxlength="255">
          </v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click.native="close()">{{ $t('label.cancelar') }}</v-btn>
          <v-btn @click.native="salvar()" color="primary" :disabled="somenteLeitura || !podeSalvar">{{ $t('label.salvar') }}</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script>

import { getMoney, getPercent } from '../../../common/functions/helpers';
import {
  buscarFornecedorPagador,
  salvarApuracaoInformacoesNotaDebito,
} from '../../../common/resources/apuracao/apuracao-contrato';
import { forceLoading, forceHideLoading } from '../../../common/functions/loading';

export default {
  data() {
    return {
      planejamentoAcaoResource: this.$api.planejamentoAcaoCadastro(this.$resource),
      dialog: false,
      apuracaoInformacoesND: {
        fornecedorPagador: {},
        justificativaFornecedorPagador: '',
        prazoPagamento: null,
        justificativaPrazoPagamento: '',
        observacaoNotaDebito: '',
      },
      idApuracao: null,
      idContrato: null,
      somenteLeitura: false,
      fornecedoresPagadores: [],
      labelFornecedorPagador: 'FORNECEDOR_PAGADOR',
      labelPrazoPagamento: 'PRAZO_PAGAMENTO',
      desabilitarJustificativaFornecedorPagador: true,
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
        requiredFunctionOpcional: (obrigatorio, label) => [
          () => {
            if (!obrigatorio) {
              return true;
            }
            const msg = this.$t('message.campo_obrigatorio');
            if (label === 'FORNECEDOR_PAGADOR') {
              return !!(this.apuracaoInformacoesND.justificativaFornecedorPagador) || msg;
            }
            if (label === 'PRAZO_PAGAMENTO') {
              return !!(this.apuracaoInformacoesND.justificativaPrazoPagamento) || msg;
            }
            return true;
          },
        ],
      },
    };
  },
  computed: {
    prazoPagamentoPreenchido() {
      return !!this.apuracaoInformacoesND.prazoPagamento;
    },
    podeSalvar() {
      return !!this.apuracaoInformacoesND.fornecedorPagador
        || !!this.apuracaoInformacoesND.prazoPagamento
        || !!this.apuracaoInformacoesND.observacaoNotaDebito;
    },
  },
  methods: {
    getPercent,
    getMoney,
    open(idContrato, idApuracao, somenteLeitura) {
      this.dialog = true;
      this.resetaCampos();
      this.idApuracao = idApuracao;
      this.idContrato = idContrato;
      this.somenteLeitura = somenteLeitura;
      if (idContrato) {
        forceLoading();
        this.buscarFornecedorPagador(idContrato, idApuracao);
      }
    },
    close() {
      this.dialog = false;
      this.$refs.formOrigem.resetValidation();
    },
    buscarFornecedorPagador(idContrato, idApuracao) {
      buscarFornecedorPagador({ idContrato, idApuracao }, this.$resource)
        .then((response) => {
          if (response.body) {
            this.apuracaoInformacoesND = response.body;
            this.buscaFornecedoresPagadores();
          } else {
            forceHideLoading();
          }
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    buscaFornecedoresPagadores() {
      const parametros = {
        idGrupoFornecedor: this.apuracaoInformacoesND ? this.apuracaoInformacoesND.idGrupoFornecedor : 0,
        idContrato: this.idContrato,
        pagador: false,
        apenasMatriz: false,
      };

      this.planejamentoAcaoResource.buscarFornecedoresPagadores(parametros)
        .then((res) => {
          this.fornecedoresPagadores = res.data;
          let fornecedorPagadorSelecionado = null;
          if (this.apuracaoInformacoesND && this.apuracaoInformacoesND.idFornecedor) {
            this.apuracaoInformacoesND.fornecedorPagador = this.fornecedoresPagadores.find(
              (el) => el.id === this.apuracaoInformacoesND.idFornecedor,
            );
            this.apuracaoInformacoesND.codNomeCnpj = this.apuracaoInformacoesND.fornecedorPagador.codNomeCnpj;
            fornecedorPagadorSelecionado = this.apuracaoInformacoesND.fornecedorPagador;
          }
          if (!fornecedorPagadorSelecionado) {
            fornecedorPagadorSelecionado = this.fornecedoresPagadores
              .find((fp) => fp.id === this.apuracaoInformacoesND.idFornecedorPagador);
          }
          if (fornecedorPagadorSelecionado) {
            fornecedorPagadorSelecionado.disabled = true;
          }
          this.verificarFornecedorPagadorPreenchido();
          forceHideLoading();
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    salvar() {
      if (!this.validaCampos()) {
        return false;
      }
      if (!this.apuracaoInformacoesND.fornecedorPagador) {
        this.apuracaoInformacoesND.justificativaFornecedorPagador = null;
      }
      if (!this.apuracaoInformacoesND.prazoPagamento) {
        this.apuracaoInformacoesND.justificativaPrazoPagamento = null;
      }
      const apuracaoAcaoInformacaoNotaDebito = {
        cod_apuracao: this.idApuracao,
        cod_contrato: this.idContrato,
        id_fornecedor_pagador: this.apuracaoInformacoesND.fornecedorPagador ? this.apuracaoInformacoesND.fornecedorPagador.id : null,
        prazo_pagamento: this.apuracaoInformacoesND.prazoPagamento || null,
        justificativa_fornecedor_pagador: this.apuracaoInformacoesND.justificativaFornecedorPagador
          ? this.apuracaoInformacoesND.justificativaFornecedorPagador.trim() : null,
        justificativa_prazo_pagamento: this.apuracaoInformacoesND.justificativaPrazoPagamento
          ? this.apuracaoInformacoesND.justificativaPrazoPagamento.trim() : null,
        observacao_nota_debito: this.apuracaoInformacoesND.observacaoNotaDebito
          ? this.apuracaoInformacoesND.observacaoNotaDebito.trim() : null,
      };
      return salvarApuracaoInformacoesNotaDebito(apuracaoAcaoInformacaoNotaDebito, this.$resource)
        .then(() => {
          this.$toast(this.$t('message.atualizado_com_sucesso'));
          this.close();
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    validaCampos() {
      if (!this.$refs.formOrigem.validate()) {
        return false;
      }
      return true;
    },
    prazoPagamentoAlterado() {
      if (!this.prazoPagamentoPreenchido) {
        this.apuracaoInformacoesND.justificativaPrazoPagamento = '';
      }
    },
    verificarFornecedorPagadorPreenchido() {
      if (!!this.apuracaoInformacoesND.fornecedorPagador
        && !!this.apuracaoInformacoesND.fornecedorPagador.id) {
        this.desabilitarJustificativaFornecedorPagador = false;
      } else {
        this.desabilitarJustificativaFornecedorPagador = true;
        this.apuracaoInformacoesND.justificativaFornecedorPagador = '';
      }
    },
    resetaCampos() {
      this.apuracaoInformacoesND = {
        fornecedorPagador: {
          id: null,
        },
        justificativaFornecedorPagador: '',
        prazoPagamento: null,
        justificativaPrazoPagamento: '',
        observacaoNotaDebito: '',
      };
      if (this.$refs.formOrigem) {
        this.$refs.formOrigem.resetValidation();
      }
    },
  },
};
</script>
